.container {
    &--white {
        background-color: color(white);
        padding-bottom: $spacing;
    }

    &--beige {
        @include margin(regular, top);
        background-color: color(brown, lighter);
        padding-bottom: $spacing;
    }

    &--green {
        @include margin(regular, top);
        background-color: color(green);
        padding-bottom: $spacing;
    }

    &--lightergreen {
        @include margin(regular, top);
        background-color: color(green, lighter);
        padding-bottom: $spacing;
    }

    &--idporten {
        @include font-size(xsmall);
        background-color: color(black);
        color: color(white);
        padding-top: $spacing--xsmall;
        padding-bottom: $spacing--xsmall;
    }
}

.container-fluid {
    /* overflow: hidden;  Will added this. And Tobias removed it. */
}

@media screen and (max-width: 992px) {
    .row-column__reverse {
        display: flex;
        flex-direction: column-reverse;
    }
}
