.form {
  &--emphasis {
    box-shadow: $border--xsmall $border--large $border--xxlarge $border--large
      rgba(0, 0, 0, 0.08);
    padding: $spacing--small;
    @include padding(xlarge, bottom) margin: $spacing 0;
    @media (min-width: $screen-sm-min) {
      box-shadow: $border--small $border--xlarge $border--xxxlarge
        $border--xlarge rgba(0, 0, 0, 0.08);
      padding-left: $spacing--xxxlarge;
      padding-right: $spacing--xxxlarge;
      margin: $spacing--large $spacing--xxxlarge * -1;
    }
  }
  &__label {
    @include font-size(regular);
    @include margin(regular, top);
    display: block;

    &--checkbox,
    &--radio {
      cursor: pointer;
      display: block;

      &:focus .form__radio,
      &:hover .form__radio,
      &:focus .form__checkbox,
      &:hover .form__checkbox {
        border-color: color(green, base);
      }
    }

    &--indent {
      //            padding-left: $spacing--xxlarge;
    }

    &--hanging {
      //            text-indent: -$spacing--xxlarge;
      //            padding-left: $spacing--xxlarge;
    }

    &--float {
      @media (min-width: $screen-sm-min) {
        margin-right: $spacing;
        float: left;
      }
    }
  }

  &__errormessage {
    color: white;
    display: inline-block;
    background-color: color(orange);
    padding: $spacing--small $spacing;
  }

  &__text {
    display: block;
    padding: $spacing--xsmall $spacing--small;
    background-color: white;
    border: $border--xsmall solid color(green, dark);
    outline: none;

    &:focus,
    &:hover {
      border: $border--xsmall solid color(green);
      list-style-type: none;
    }

    &:focus,
    &:hover {
      transition: all $timeunit ease;
    }

    &:disabled {
      border-color: color(gray);
      color: color(gray);
      background-color: transparent;
    }

    &--xs {
      width: 20%;
      min-width: 90px;
    }

    &--small {
      width: 50%;
      min-width: 220px;
    }

    &--medium {
      width: 80%;
      min-width: 220px;
    }

    &--large {
      width: 100%;
      min-width: 220px;
    }

    &--error {
      background-color: transparent;
      border: $border--xsmall solid color(orange);
      list-style-type: none;
      color: color(orange);

      &:focus,
      &:hover {
        border: $border--xsmall solid color(orange);
        transition: all $timeunit ease;
      }
    }

    &--hidden {
      display: none;
    }

    &--mobile {
      height: 51px;
      margin-top: 0.8rem;
    }
  }

  &__button {
    @include margin(large, top);
    display: inline-block;
    padding: $spacing--small $spacing--large $spacing--small $spacing--small;
    background-repeat: no-repeat;
    background-position: right $spacing--small center;
    cursor: pointer;
    border-radius: $border--large;
    text-decoration: none;

    p {
      margin-top: 0;
    }

    &--primary {
      color: color(white);
      background-color: color(green, dark);
      border: $border--xsmall solid color(green, dark);
      background-image: url("../svg/arrow-white-right.svg");

      a {
        color: color(white);
        border-bottom: 0;
      }

      &:focus,
      &:hover,
      &:active {
        background-image: url("../svg/arrow-green-right.svg");
        background-color: transparent;
      }

      &:focus,
      &:hover {
        border-color: color(green, dark);
        color: color(black);

        a {
          color: color(black);
        }
      }

      &:active {
        border-color: color(green);
        color: color(green, dark);
      }
    }

    &--secondary {
      color: color(green, dark);
      background-color: transparent;
      border: $border--xsmall solid color(green, light);
      background-image: url("../svg/arrow-green-right.svg");

      &:focus,
      &:hover {
        background-color: color(brown, light);
        border: $border--xsmall solid color(green, dark);
        color: color(black);
      }

      &:active {
        border: $border--xsmall solid color(green);
        color: color(green, dark);
      }
    }

    &--orange {
      color: color(white);
      background-color: color(orange);
      border: $border--xsmall solid color(orange);
      background-image: url("../svg/arrow-white-right.svg");

      &:focus,
      &:hover {
        background-image: url("../svg/arrow-orange-right.svg");
        background-color: color(white);
        color: color(orange);
      }
    }

    &--small {
      @include font-size(small, true);
      margin: 0;
      padding: $spacing--xxxsmall $spacing--large $spacing--xxxsmall
        $spacing--xxsmall;
    }

    &--oneline {
      @include margin(xxlarge, top);
      padding-top: $spacing--xsmall;
      padding-bottom: $spacing--xsmall;
    }

    &--float {
      @media (min-width: $screen-sm-min) {
        margin-left: $spacing;
        float: left;
      }
    }
  }

  &__select {
    background-color: color(white);
    display: block;
    background-image: url("../svg/arrow-green-down.svg");
    background-repeat: no-repeat;
    background-position: right $spacing center;
    cursor: pointer;
    padding: $spacing--xsmall $spacing--xlarge $spacing--xsmall $spacing;
    border: $border--xsmall solid color(green);
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &::-ms-expand {
      display: none;
    }

    &--fullwidth {
      width: 100%;
    }
  }

  &__checkbox,
  &__radio {
    width: 20px;
    height: 20px;
    border: $border--small solid color(green, light);
    background-color: color(white);
    display: inline-block;
    vertical-align: middle;
    margin-right: $spacing--xsmall;

    @media (min-width: $screen-sm-min) {
      width: 30px;
      height: 30px;
    }

    &:focus,
    &:hover {
      border-color: color(green, base);
    }
  }

  &__radio {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      left: 3px;
      top: 3px;
      transform: scale(0);
      transition: all $timeunit ease;

      @media (min-width: $screen-sm-min) {
        width: 18px;
        height: 18px;
        left: 4px;
        top: 4px;
      }
    }
  }

  &__radio,
  &__radio::after {
    border-radius: 50%;
  }

  &__legend {
    @include font-size(large);
    @include padding(xlarge, top);
    max-width: 100%;

    &--border {
      @include font-size(regular);
      color: color(green, dark);
      border-bottom: $border--xsmall solid color(green, dark);
      width: 100%;
      padding-bottom: $spacing--xsmall;
    }
  }

  &__zipcode {
    display: inline-block;
    vertical-align: bottom;
  }

  &__location {
    display: block;
    padding: 0;
    margin-top: $spacing--xsmall;
    vertical-align: bottom;
    @media (min-width: $screen-md-min) {
      display: inline-block;
      padding: $spacing--xsmall $spacing;
      margin-top: 0;
    }
  }

  &__file {
    @include font-size(small, true);
    @include margin(xsmall, top);
    line-height: 1;
    display: block;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="checkbox"]:checked + .form__checkbox {
  background-image: url("../svg/ja.svg");
  background-size: contain;
}

input[type="radio"]:checked + .form__radio::after {
  background-color: color(green, base);
  transform: scale(1);
  transition: all $timeunit ease;
}

.button {
  &--link {
    border: 0;
    padding: 0;
    background: transparent;
    text-decoration: underline;
    color: color(orange);

    &:hover {
      text-decoration: none;
      cursor: pointer;
    }
  }
}

.alert {
  @include padding(large);

  @media (min-width: $screen-sm-min) {
    margin-top: $spacing--large;
    margin-bottom: $spacing--large;
  }
  > *:first-child {
    margin-top: 0;
  }

  &-success {
    background-color: color(green, lighter);
  }

  &-danger {
    background-color: color(orange);

    * {
      color: white;
    }
  }
}
