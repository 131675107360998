.text__text,
.html__text,
.factbox {
  ul,
  ol {
    list-style: none;

    li {
      @include margin(small, top);
      position: relative;
      padding-left: $spacing--large;

      &::before {
        border-radius: 100%;
        position: absolute;
      }
    }
  }

  ul {
    li {
      &::before {
        content: "";
        background-color: color(green, dark);
        top: 8px;
        left: 6px;
        width: 12px;
        height: 12px;
        @media (min-width: $screen-lg-min) {
          top: 6px;
        }
      }
    }
  }

  ul.yes li,
  ul.no li {
    &::before {
      background-repeat: no-repeat;
      background-size: contain;
      top: 6px;
      left: 4px;
      width: 16px;
      height: 16px;
    }
  }

  ul.yes li {
    &::before {
      background-image: url("../svg/bullet-ja.svg");
    }
  }

  ul.no li {
    &::before {
      background-color: color(orange);
      background-image: url("../svg/bullet-nei.svg");
    }
  }

  ol {
    li {
      counter-increment: item;

      &::before {
        @include font-size(xsmall);
        text-align: center;
        content: counter(item);
        background-color: color(blue, light);
        top: 2px;
        left: 0;
        width: 24px;
        height: 24px;
        padding-top: 2px;
      }
    }
  }

  ol.ol-dashed {
    li {
      @include font-size(small);
      @include margin(xsmall, top);

      &::before {
        background-color: color(white);
        border-color: color(orange);
        border-width: $border--xsmall;
        border-style: dashed;
      }
    }
  }

  dl {
    dt {
      @include font-size(large);
    }

    dd {
      @include font-size(large);
      margin-top: 0;
    }
  }
}
