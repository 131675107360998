.navigationlist {
    list-style: none;

    &__item {
        border-bottom: $border--xsmall solid color(green);
        margin-top: 0 !important;

        &--title {
            @include font-family(primary, bold);
            background-color: color(green);
            color: color(white);
            border-bottom: 0;
        }

        &--no-link {
            padding: $spacing--small;
        }

        &--calendar {
            background-image: url('../svg/icon-hentedag-lg.svg');
            background-position: right $spacing--xsmall center;
            background-repeat: no-repeat;
        }

        &--footer {
            margin-left: -$spacing--xsmall;
        }

        &::before {
            display: none !important;
        }
        &--brown-factbox {
            padding-left: 0 !important;
        }
    }

    &__link {
        display: block;
        text-decoration: none;
        padding: $spacing--small $spacing--xxlarge $spacing--small $spacing--small;
        color: color(black);

        &:hover,
        &:focus {
            color: color(green);
            background-color: color(brown, lighter);
            text-decoration: underline;
        }

        &--arrow {
            background-image: url('../svg/arrow-green-circle-right.svg');
            background-size: $spacing--large;
            background-position: right $spacing--small center;
            background-repeat: no-repeat;
            &:hover,
            &:focus {
                text-decoration: none;
            }
        }

        &--collapse {
            background-image: url('../svg/arrow-green-down.svg');
            background-position: right $spacing--small center;
            background-repeat: no-repeat;
        }

        &--footer {
            background-image: url('../svg/arrow-green-circle-right.svg');
            background-size: $spacing--large;
            background-position: right $spacing--small center;
            background-repeat: no-repeat;
            color: color(black) !important;

            &:hover,
            &:focus {
                background-color: rgba(255, 255, 255, 0.5);
            }
        }
        &--brown-factbox {
            &:hover,
            &:focus {
                background-color: color(brown, light);
            }
        }
    }
}
