table {
    @include font-size(xsmall);
    width: 100%;
    border: 0;
    overflow-x: auto;
    display: block;
    @media (min-width: $screen-xs-min) {
        @include font-size(small);
        border: $border--xsmall solid color(green);
        border-collapse: separate;
        border-style: dashed;
        display: table;
    }
    @media (min-width: $screen-sm-min) {
        @include font-size(regular);
        border-width: $border--small;
    }
}
caption {
    @include font-family(primary, bold);
    @include font-size(xlarge);
    @include margin(xlarge, top);
    @include margin(regular, bottom);
    color: color(green, dark);
    text-align: left;
    page-break-before: always;
}

tbody tr {
    &:nth-child(even) {
        background-color: color(blue, light);
    }
}

thead + tbody tr {
    &:nth-child(even) {
        background-color: transparent;
    }
    &:nth-child(odd) {
        background-color: color(blue, light);
    }
}

th,
td {
    @include padding(small);
}

th {
    @include font-family(primary);
    color: color(black);
    text-align: left;
}
thead th {
    @include font-family(primary, bold);
}

.half-width-table {
    th,
    td {
        width: 50%;
    }
}

.small-table {
    @media (min-width: $screen-sm-min) {
        @include font-size(small);
    }
}

.inner-border-table {
    border-collapse: collapse;
    border: 0;

    td,
    th {
        border: $border--xsmall solid color(green);
        border-style: dashed;
    }

    tr:first-child th {
        border-top: 0;
    }

    tr:last-child td {
        border-bottom: 0;
    }

    tr td:first-child,
    tr th:first-child {
        border-left: 0;
    }

    tr td:last-child,
    tr th:last-child {
        border-right: 0;
    }

    &--left {
        tr:first-child td {
            border-top: 0;
        }

        tr:last-child th {
            border-bottom: 0;
        }
    }

    &__link {
        @media only screen {
            display: block;
            padding-right: $spacing--large;
            background-image: url('../svg/arrow-orange-right.svg');
            background-size: $spacing--small;
            background-position: right center;
            background-repeat: no-repeat;
        }
    }
}
