@media (min-width: $screen-sm-min) {
  .frontpage-bg-gradient::before {
    background: linear-gradient(
      to bottom,
      color(blue, light),
      rgba(255, 255, 255, 0)
    );
    content: "";
    position: absolute;
    height: 300px;
    width: 100%;
    left: 0;
    right: 0;
  }
}

.hr {
  @include margin(large, top);
  margin-bottom: 0;
  &--green {
    border-top: $border--small solid color(green, light);
  }
}

.mt {
  @include margin(large, top);
}

.mt-xlarge {
  @include margin(xlarge, top);
}

.mt-xxlarge {
  @include margin(xxlarge, top);
}

.mt-xxxlarge {
  @include margin(xxxlarge, top);
}

.mt-xxxxlarge {
  @include margin(xxxxlarge, top);
}

.no-mt {
  margin-top: 0;
}

.invisible {
  visibility: hidden;
}

.hidden {
  display: none;
}

.visibility-hidden {
  visibility: hidden;
}

.clear {
  clear: both;
  padding-top: $spacing;
}

.vertical-align {
  display: flex;
  align-items: center;
}

.vertical-align-xs {
  @media (min-width: $screen-xs-min) {
    display: flex;
    align-items: center;
  }
}

.vertical-align-sm {
  @media (min-width: $screen-sm-min) {
    display: flex;
    align-items: center;
  }
}

.vertical-align-md {
  @media (min-width: $screen-md-min) {
    display: flex;
    align-items: center;
  }
}

.vertical-align-lg {
  @media (min-width: $screen-lg-min) {
    display: flex;
    align-items: center;
  }
}
.row--flex {
  @media (min-width: $screen-md-min) {
    display: flex;
  }
}
