.header {
    @include padding(regular);
    @include font-size(small);
    border-top: $border--large solid color(green);
    background-color: color(white);

    &__logo {
        height: 45px;
        width: auto;
        vertical-align: middle;
        @media (min-width: $screen-lg-min) {
            height: 65px;
        }
    }
}

.menu {
    margin-top: 0;

    @media (max-width: $screen-sm-max) {
        @include margin(small, top);
    }

    &__item {
        @media (min-width: $screen-lg-min) {
            vertical-align: top;
            display: inline-block;

            &--search {
                float: right;
            }
        }

        &--desktop {
            @media (max-width: $screen-lg-min) {
                display: none;
            }
        }

        &--hidden {
            display: none;
        }

        &--mobile {
            @media (min-width: $screen-lg-min) {
                display: none;
            }
        }
    }

    &__link {
        display: block;
        text-decoration: none;
        border-bottom: $border--xsmall solid color(green);
        @include padding(small, top);
        @include padding(large, right);
        @include padding(small, bottom);
        @include padding(small, left);

        color: color(black);
        background-position: right $spacing--small center;
        background-repeat: no-repeat;
        @media (min-width: $screen-lg-min) {
            display: inline-block;
            border-bottom: 0;
            border-radius: $spacing--xlarge;
        }

        &:focus,
        &:hover,
        &--active {
            background-color: color(brown, light);
        }

        &--closed {
            background-image: url('../svg/arrow-green-down.svg');
        }

        &--open {
            background-image: url('../svg/arrow-green-up.svg');
            background-color: color(brown, light);
            color: color(green, dark);
        }

        &--direct {
            background-image: url('../svg/arrow-green-right.svg');
        }

        &--mypage {
            background-image: url('../svg/arrow-green-right.svg');
            @media (min-width: $screen-lg-min) {
                background-image: none;
                padding: $spacing--xsmall;
                border: $border--xsmall solid color(green);
                border-radius: $border--large;
            }
        }
    }
}
// Animated hamburger menu
$bar-height: $border--small;

.nav-button {
    text-decoration: none;
    color: color(black);
    text-align: right;
    &__icon {
        width: $spacing;
        height: $spacing;
        cursor: pointer;
        display: inline-block;
        position: relative;
        vertical-align: middle;
    }
    // Add this modifier to make nav button responsive
    &--responsive {
        width: 100%;
        height: auto;
        padding-bottom: 100%;
    }

    &__title {
        display: inline-block;
        margin-right: 10px;
    }

    span {
        background-color: color(black);
        display: block;
        width: 100%;
        height: $bar-height;
        position: absolute;
        left: 0;
        transition: all $timeunit * 4 linear;
        transform: translateZ(0);
        will-change: auto;

        &:nth-child(1) {
            top: 0;
        }

        &:nth-child(2) {
            margin-top: -$bar-height / 2;
            top: 50%;
        }

        &:nth-child(3) {
            margin-top: -$bar-height / 2;
            top: 50%;
        }

        &:nth-child(4) {
            bottom: 0;
        }
    }

    &.is-open {
        span {
            &:nth-child(1) {
                opacity: 0;
                top: 50%;
                margin-top: -$bar-height / 2;
                transform: scale(0.3);
            }

            &:nth-child(2) {
                transform: rotate(45deg);
                transition-delay: 0;
            }

            &:nth-child(3) {
                transform: rotate(-45deg);
                transition-delay: 0;
            }

            &:nth-child(4) {
                bottom: 50%;
                margin-top: -$bar-height / 2;
                opacity: 0;
                transform: scale(0.3);
            }
        }
    }
}
