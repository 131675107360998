.accordion {
    @include margin(regular, top);
    ~ .accordion {
        margin-top: 0;
    }
    .text__title {
        &--expandable {
            @include font-family(primary);
            @include font-size(regular);
            padding: $spacing--small $spacing--large $spacing--small 0;
            margin: 0;
            cursor: pointer;
            border-bottom: $border--xsmall solid color(green);
            color: color(black);

            &:hover,
            &:focus {
                background-color: color(brown, light);
            }
        }

        &--closed {
            background-image: url('../svg/arrow-green-down.svg');
            background-position: right $spacing--small center;
            background-repeat: no-repeat;
        }

        &--open {
            background-image: url('../svg/arrow-green-up.svg');
            background-position: right $spacing--small center;
            background-repeat: no-repeat;
        }
    }

    .text__text {
        @include margin(regular, top);
        padding-bottom: $spacing;

        &--closed {
            display: none;
        }
    }
}
