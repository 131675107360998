.idporten {
    padding: $spacing--xxsmall 0 $spacing--xxsmall $spacing--large;
    position: relative;

    &::before {
        position: absolute;
        top: 3px;
        left: 0;
        width: 24px;
        height: 24px;
        content: '';
        background-image: url('../img/idporten.svg');
        background-size: contain;
        background-repeat: no-repeat;
    }

    &__link {
        color: color(white);
    }
}
