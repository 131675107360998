.illustrations {
    &__container {
        position: relative;
        z-index: 1;
        isolation: isolate;

    }

    &__image {
        width: 100%;

        &--explain {
            transition: opacity $timeunit * 12 ease;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;

            &:hover,
            &:focus {
                opacity: 1;
            }
        }
    }

    &__title,
    &__text {
        @include font-size(regular);
        padding-left: $spacing;
    }

    &__title {
        @include font-family(primary, bold);
        @include margin(xlarge, top);

        @for $i from 1 through 5 {
            &--#{$i} {
                &::before {
                    @include font-family(primary);
                    @include font-size(xsmall);
                    content: '#{$i}';
                    background-color: color(blue, light);
                    border-radius: 100%;
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    padding-top: 2px;
                    text-align: center;
                    position: absolute;
                    left: 0;
                }
            }
        }
    }

    &__text {
        margin-top: 0;
    }
}
