// Style jQuery UI Calendar picker

.ui-datepicker {
    @include font-family(primary);
    @include font-size(regular);
    border: $border--xsmall solid color(green);
    border-radius: 0;
    padding: 0;
}

.ui-datepicker-header {
    background: color(green, light);
    border: 0;
    border-radius: 0;
}

.ui-datepicker-calendar {
    background: color(white);
    border: 0;
    margin-right: $spacing--small;

    td a,
    td span {
        background: none !important;
        border: 0 !important;
    }
}

.ui-datepicker th,
.ui-datepicker td {
    text-align: right;
    width: calc(100%/7);
}

.ui-datepicker .ui-datepicker-next:hover,
.ui-datepicker .ui-datepicker-prev:hover  {
    background: none !important;
    border: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
}
