.image {
  @include margin(regular, top);
  max-width: 100%;

  &--full {
    max-width: none;
    width: 100%;
  }

  &--spaced {
    margin-bottom: $spacing;
    padding-top: $spacing;
  }

  &--factbox-breakout {
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: -36px;
    max-width: none;
    width: calc(100% + 40px);
  }
}

.map {
  width: 100%;
}

@media (min-width: $screen-sm-min) {
  .map {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
  }
  .mw-100 {
    max-width: 100px;
  }
  .mw-200 {
    max-width: 200px;
  }
  .mw-300 {
    max-width: 300px;
  }
  .mw-400 {
    max-width: 400px;
  }
  .mw-500 {
    max-width: 500px;
  }
  .mw-600 {
    max-width: 600px;
  }
  .mw-700 {
    max-width: 700px;
  }
}

.social {
  &::before {
    width: 20px;
    height: 20px;
    margin-right: $spacing--xsmall;
    display: inline-block;
    content: " ";
    background-size: contain;
  }

  &--facebook::before {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNjYuODkzIiBoZWlnaHQ9IjI2Ni44OTUiIHZpZXdCb3g9IjAgMCAyNjYuODkzIDI2Ni44OTUiPjxwYXRoIGZpbGw9IiMzQzVBOTkiIGQ9Ik0yNDguMDgyIDI2Mi4zMDdjNy44NTQgMCAxNC4yMjMtNi4zNyAxNC4yMjMtMTQuMjI1VjE4LjgxMmMwLTcuODU3LTYuMzY4LTE0LjIyNC0xNC4yMjMtMTQuMjI0SDE4LjgxMmMtNy44NTcgMC0xNC4yMjQgNi4zNjctMTQuMjI0IDE0LjIyNHYyMjkuMjdjMCA3Ljg1NSA2LjM2NiAxNC4yMjUgMTQuMjI0IDE0LjIyNWgyMjkuMjd6Ii8+PHBhdGggZmlsbD0iI0ZGRiIgZD0iTTE4Mi40MSAyNjIuMzA3di05OS44MDNoMzMuNDk4bDUuMDE2LTM4Ljg5NUgxODIuNDFWOTguNzc2YzAtMTEuMjYgMy4xMjYtMTguOTM1IDE5LjI3NC0xOC45MzVsMjAuNTk2LS4wMVY0NS4wNDZjLTMuNTYyLS40NzQtMTUuNzg4LTEuNTMzLTMwLjAxMi0xLjUzMy0yOS42OTUgMC01MC4wMjUgMTguMTI2LTUwLjAyNSA1MS40MTN2MjguNjg0aC0zMy41ODV2MzguODk0aDMzLjU4NXY5OS44MDNoNDAuMTY2eiIvPjwvc3ZnPg==");
  }

  &--twitter::before {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0MDAgNDAwIj48c3R5bGU+LnN0MHtmaWxsOiMxREExRjI7fSAuc3Qxe2ZpbGw6I0ZGRkZGRjt9PC9zdHlsZT48cGF0aCBjbGFzcz0ic3QwIiBkPSJNMCAwaDQwMHY0MDBIMHoiIGlkPSJEYXJrX0JsdWUiLz48cGF0aCBjbGFzcz0ic3QxIiBkPSJNMTUzLjYgMzAxLjZjOTQuMyAwIDE0NS45LTc4LjIgMTQ1LjktMTQ1LjkgMC0yLjIgMC00LjQtLjEtNi42IDEwLTcuMiAxOC43LTE2LjMgMjUuNi0yNi42LTkuMiA0LjEtMTkuMSA2LjgtMjkuNSA4LjEgMTAuNi02LjMgMTguNy0xNi40IDIyLjYtMjguNC05LjkgNS45LTIwLjkgMTAuMS0zMi42IDEyLjQtOS40LTEwLTIyLjctMTYuMi0zNy40LTE2LjItMjguMyAwLTUxLjMgMjMtNTEuMyA1MS4zIDAgNCAuNSA3LjkgMS4zIDExLjctNDIuNi0yLjEtODAuNC0yMi42LTEwNS43LTUzLjYtNC40IDcuNi02LjkgMTYuNC02LjkgMjUuOCAwIDE3LjggOS4xIDMzLjUgMjIuOCA0Mi43LTguNC0uMy0xNi4zLTIuNi0yMy4yLTYuNHYuN2MwIDI0LjggMTcuNyA0NS42IDQxLjEgNTAuMy00LjMgMS4yLTguOCAxLjgtMTMuNSAxLjgtMy4zIDAtNi41LS4zLTkuNi0uOSA2LjUgMjAuNCAyNS41IDM1LjIgNDcuOSAzNS42LTE3LjYgMTMuOC0zOS43IDIyLTYzLjcgMjItNC4xIDAtOC4yLS4yLTEyLjItLjcgMjIuNiAxNC40IDQ5LjYgMjIuOSA3OC41IDIyLjkiIGlkPSJMb2dvX194MjAxNF9fRklYRUQiLz48L3N2Zz4=");
  }
}

figure {
  @include margin(xxlarge, top);
  figcaption {
    @include font-size(small);
    margin-top: 0;
    background-color: color(green, lighter);
    padding: $spacing--xsmall $spacing--small;
  }
}

.garbage-container {
  width: auto;
  height: 100%;
  max-height: 80px;

  @media (min-width: 540px) {
    max-height: 60px;

    &:nth-child(n + 2) {
      margin-left: $spacing--small;
    }
  }
  @media (min-width: $screen-sm-min) {
    max-height: 100px;
  }
  @media (min-width: $screen-md-min) {
    max-height: 120px;
  }
  @media (min-width: $screen-lg-min) {
    max-height: 140px;
  }
  &--35l {
    @media (min-width: $screen-sm-min) {
      max-height: 50px;
    }
    @media (min-width: $screen-md-min) {
      max-height: 60px;
    }
    @media (min-width: $screen-lg-min) {
      max-height: 70px;
    }
  }
  &--small {
    @media (min-width: $screen-sm-min) {
      max-height: 60px;
    }
    @media (min-width: $screen-md-min) {
      max-height: 80px;
    }
    @media (min-width: $screen-lg-min) {
      max-height: 100px;
    }
  }
  &--35l-small {
    @media (min-width: $screen-sm-min) {
      max-height: 30px;
    }
    @media (min-width: $screen-md-min) {
      max-height: 40px;
    }
    @media (min-width: $screen-lg-min) {
      max-height: 50px;
    }
  }
}

.loading-circle {
  width: 290px;
  height: 290px;
  background-image: url("../images/svg/rfd-circle.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin-left: auto;
  margin-right: auto;
}
