.chart {
    margin-top: $spacing;

    &__label {
        @include font-size(xxlarge);
        position: absolute;
        top: 33%;
        width: 100%;
        text-align: center;
        margin-top: $spacing;
        padding-right: $spacing--xsmall;
    }

    &__title {
        text-align: center;
    }
}
