$fonts: (
    primary: (
        base: 'glober',
        fallback: 'sans-serif',
    ),
);

$font-size: (
    xxlarge: (
        xs: (1.4rem, 1.4),
        sm: (1.6rem, 1.3),
        md: (1.8rem, 1.3),
        lg: (2rem, 1.3),
    ),
    xlarge: (
        xs: (1.2rem, 1.4),
        sm: (1.3rem, 1.4),
        md: (1.4rem, 1.4),
        lg: (1.6rem, 1.3),
    ),
    large: (
        xs: (1.0rem, 1.6),
        sm: (1.2rem, 1.4),
        md: (1.3rem, 1.4),
        lg: (1.4rem, 1.4),
    ),
    regular: (
        xs: (1.0rem, 1.6),
        sm: (1.2rem, 1.4),
    ),
    small: (
        xs: (1.0rem, 1.6),
    ),
    xsmall: (
        xs: (0.8rem, 1.6),
    ),
);
