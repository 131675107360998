.select2-container--rfd {
    background-color: color(white);
    display: block;
    background-image: url('../svg/arrow-green-down.svg');
    background-repeat: no-repeat;
    background-position: right $spacing center;
    cursor: pointer;

    .select2-dropdown {
        border: $border--xsmall solid color(green);
        border-top: 0;
        border-radius: 0;
    }

    .select2-results__option {
        padding: $spacing--xsmall $spacing--xlarge $spacing--xsmall $spacing;
    }

    .select2-selection--single {
        padding: $spacing--xsmall $spacing--xlarge $spacing--xsmall $spacing;
        background: none;
        border: $border--xsmall solid color(green);
        height: auto;

        .select2-selection__rendered {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .select2-results__option--highlighted {
        background-color: color(green);
        color: color(white);
    }

    .select2-results__option[aria-selected='true'] {
        background-color: color(green, light);
        color: color(black);
    }
}
