$timeunit: 0.05s;

$spacing--xxxxlarge: 4.2rem;
$spacing--xxxlarge: 3.4rem;
$spacing--xxlarge: 2.8rem;
$spacing--xlarge: 2.4rem;
$spacing--large: 2.1rem;
$spacing: 1.2rem;
$spacing--small: 0.8rem;
$spacing--xsmall: 0.6rem;
$spacing--xxsmall: 0.4rem;
$spacing--xxxsmall: 0.2rem;
$spacing--xxxxsmall: 0.1rem;

$border--xxxlarge: 30px;
$border--xxlarge: 20px;
$border--xlarge: 10px;
$border--large: 6px;
$border: 3px;
$border--small: 2px;
$border--xsmall: 1px;

$margin: (
    xxxxlarge: (
        xs: $spacing--xlarge,
        sm: $spacing--xxlarge,
        md: $spacing--xxxlarge,
        lg: $spacing--xxxxlarge,
    ),
    xxxlarge: (
        xs: $spacing--large,
        sm: $spacing--xlarge,
        md: $spacing--xxlarge,
        lg: $spacing--xxxlarge,
    ),
    xxlarge: (
        xs: $spacing,
        sm: $spacing--large,
        md: $spacing--xlarge,
        lg: $spacing--xxlarge,
    ),
    xlarge: (
        xs: $spacing,
        sm: $spacing,
        md: $spacing--large,
        lg: $spacing--xlarge,
    ),
    large: (
        xs: $spacing--xsmall,
        sm: $spacing--small,
        md: $spacing,
        lg: $spacing--large,
    ),
    regular: (
        xs: $spacing--xsmall,
        sm: $spacing--xsmall,
        md: $spacing--small,
        lg: $spacing,
    ),
    small: (
        xs: $spacing--xsmall,
        sm: $spacing--small,
    ),
    xsmall: (
        xs: $spacing--xsmall,
    ),
    xxsmall: (
        xs: $spacing--xxsmall,
    ),
    xxxsmall: (
        xs: $spacing--xxxsmall,
    ),
    xxxxsmall: (
        xs: $spacing--xxxxsmall,
    ),
);
