@import 'reset';
@import 'bootstrap-grid/grid12';
@import 'jquery-ui/jquery.ui.core';
@import 'jquery-ui/jquery.ui.theme';
@import 'jquery-ui/jquery.ui.datepicker';
@import 'settings/breakpoints';
@import 'settings/colors';
@import 'settings/fonts';
@import 'settings/units';
@import 'settings/mixins';

* {
    box-sizing: border-box;
}

@import 'typography';
@import 'images';
@import 'containers';
@import 'header';
@import 'idporten';
@import 'footer';
@import 'lists';
@import 'navigation-list';
@import 'image-list';
@import 'accordion';
@import 'forms';
@import 'illustrations';
@import 'tables';
@import 'factbox';
@import 'chart';
@import 'misc';
@import 'employee';
@import 'select2/select2';
@import 'select2-override';
@import 'datepicker-override';
@import 'print';
@import 'search';
@import 'nav';
@import 'breadcrumbs';
