@media print {
    .container {
        width: 100%;
    }

    .ed24 {
        display: none;
    }

    .hide-when-printing {
        display: none;
    }

    .show-when-printing {
        display: block;
    }

    .frontpage-bg-gradient::before {
        background: none;
    }

    .pickup-title {
        @include font-size(xxlarge);
    }

    .pickup-logo {
        width: 80px;
    }

    .pickup-updated {
        margin-top: $spacing--large;
    }

    .navigationlist {
        &--pickup {
            @include margin(xxlarge, top);
            -moz-column-count: 3;
            -webkit-column-count: 3;
            column-count: 3;
        }

        &__item {
            &--pickup {
                @include padding(small, top);
                padding-right: 0;
                padding-bottom: 0;
                padding-left: 0;
                border: 0;
            }

            &--title {
                -moz-column-span: all;
                -webkit-column-span: all;
                column-span: all;
                border-bottom: $border--xsmall solid color(green);
                background-color: transparent;
                color: color(black);
            }
        }
    }
    table {
        caption-side: top;
        a {
            color: color(black);
            text-decoration: none;
        }
        &#alternate-hours {
            page-break-before: always;
        }
    }
    caption {
        text-align: center;
    }

}
