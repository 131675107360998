.factbox {
    @include margin(xlarge, top);
    border: $border solid color(brown, light);
    padding: $spacing--small;

    @media(min-width: $screen-sm-min) {
        padding: $spacing;
        border-width: $border--xlarge;
    }

    &--active {
        border-color: color(green, dark);
    }

    &--orange {
        border-color: color(orange);
        margin-bottom: $spacing;
    }

    &--green {
        border-color: color(green);
    }

    &--light-green {
        border-color: color(green,light);
        border-width: $border--small;
    }

    &--dashed {
        border-color: color(orange);
        border-width: $border--small;
        border-style: dashed;
    }

    &--full-height {
        min-height: 85vh;
        margin-bottom: $spacing--xxxlarge;
    }

    &--transparent {
        border: 0;
        padding: 0;
    }

    &--brown-background {
        border: 0;
        background-color: color(brown, lighter);
    }

    &--green-background {
        border: 0;
        background-color: color(green);

        h2,
        p,
        a,
        dl {
            color: color(white);
        }
    }

    &__title {
        margin-top: 0;
        &--ml {
            margin-left: $spacing--small;
        }
    }
}
