$colors: (
    white: (
        base:    #fff,
    ),
    black: (
        base:    #333,
    ),
    green: (
        base:    rgb(58, 124, 55),
        light:   #c3d7c3,
        lighter: #ebf1eb,
        dark:    #285626,
        darker:  #112510,
    ),
    gray: (
        base:    rgb(160, 163, 164),
    ),
    blue: (
        base:    rgb(0, 171, 170),
        light:   #e5f6f6,
    ),
    orange: (
        base:    #ce4703,
    ),
    brown: (
        base:    rgb(129, 112, 96),
        light:   #e5e2df,
        lighter:   #f2f0ef,
    ),
);

@function color($color, $tone: 'base') {
    @return map-get(map-get($colors, $color), $tone);
}

@each $name, $settings in $colors {
    @each $variant, $color in $settings {
        .#{$name}--#{$variant} {
            background-color: $color;
        }
    }
}
