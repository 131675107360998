.footer {
    background-color: color(blue, light);
    @include margin(xxxxlarge, top);
    padding-bottom: $spacing--xxxxlarge;
    background-size: 30%;
    background-repeat: no-repeat;
    background-image: url('../svg/cycle.svg');
    background-position: right $spacing--small center;


    @media(min-width: $screen-md-min) {
        background-size: 20%, 15%;
        background-image: url('../svg/cycle.svg'), url('../svg/cycle.svg');
        background-position: right $spacing--xxlarge top $spacing--xxlarge, left $spacing--xxlarge bottom $spacing--xxlarge;
    }
    &__menu {
        @include margin(xxlarge, top);
        @include margin(xxlarge, bottom);
        margin-left: $spacing--small;
    }

    &__logo {
        @include margin(large, top);
        width: 80px;
    }
}

.imprint {
    &__text {
        margin-top: 0;
    }
}
