// assure that hamburger menu is clickable
.js-nav-button {
    z-index: 2;
    position: relative;
}

.search {
    @include margin(xxxlarge, top);
    @media (min-width: $screen-lg) {
        margin-top: 0;
    }
    &__input {
        @include font-size(small);
        width: 100%;
        border: $border--xsmall solid color(gray);
        border-radius: $border--large;
        @media (min-width: $screen-lg) {
            width: 150px;
        }

        &--icon {
            background-image: url('../svg/search.svg');
            background-position: right $spacing--small center;
            background-repeat: no-repeat;
            padding-right: $spacing--large;
        }
    }
}
.autocompletelist {
    @include margin(small, top);
    list-style: none;
    box-shadow: $border--xsmall $border--large $border--xxlarge $border--large rgba(0, 0, 0, 0.08);

    @media (min-width: $screen-lg) {
        position: absolute;
        transform: translate(-250px);
        z-index: 1;
    }
    &__item {
        @include font-size(small);
        text-align: left;
        padding: $spacing--small;
        background-color: color(white);
        cursor: pointer;
        width: 100%;
        border: 0;

        &:not(:last-child) {
            border-bottom: $border--xsmall solid color(gray);
        }
        @media (min-width: $screen-lg) {
            width: 400px;
        }

        &:hover,
        &:focus {
            color: color(green);
            background-color: color(brown, lighter);
            text-decoration: underline;
        }

        &--true {
            background-color: color(brown, lighter);
        }
    }
}
