.employee {
    @include margin(xlarge, top);

    &__name {
        @include font-size(regular);
        margin-top: 0;
        a {
            color: color(green, dark);
        }
    }

    &__details {
        @include font-size(small);
        margin-top: 0;
        overflow-wrap: break-word;
        word-wrap: break-word;
        p {
            margin-top: 0;
        }
    }

    &__photo {
        width: 100%;
        max-width: 120px;
        border-radius: 50%;
    }
}
