.breadcrumbs {
    @include font-size(xsmall);

    list-style: none;

    @media (min-width: $screen-sm-min) {
        @include margin(small, bottom);
    }
    @media (min-width: $screen-lg-min) {
        padding-left: $spacing;
        margin-top: 0;
    }

    &__item {
        display: inline-block;
    }

    &__link {
        text-decoration: none;
        color: color(green);
        padding-right: $spacing;
        background-image: url('../svg/arrow-green-right.svg');
        background-size: $spacing--xsmall;
        background-position: right $spacing--xxxsmall center;
        background-repeat: no-repeat;

        &:focus,
        &:hover {
            color: color(green);
            text-decoration: underline;
        }
    }
}
