@font-face {
    font-family: 'glober';
    src: url('fontfabric_-_globerregular-webfont.woff2') format('woff2'), url('fontfabric_-_globerregular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'glober';
    src: url('fontfabric_-_globersemibold-webfont.woff2') format('woff2'), url('fontfabric_-_globersemibold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'glober';
    src: url('fontfabric_-_globerregularitalic-webfont.woff2') format('woff2'), url('fontfabric_-_globerregularitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'glober';
    src: url('fontfabric_-_globersemibolditalic-webfont.woff2') format('woff2'), url('fontfabric_-_globersemibolditalic-webfont.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

body,
input,
select,
textarea,
button {
    @include font-family(primary);
    @include font-size(regular);
    color: color(black);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
dl,
legend,
dt,
dd,
table {
    @include margin(regular, top);
}

h1,
h2,
h3,
h4 {
    @include font-family(primary, bold);
    color: color(green, dark);
}

h5,
h6 {
    @include font-family(primary);
    color: color(green, darker);
}

h1 {
    @include font-size(xxlarge);
    @include margin(xxlarge, top);
}

h2 {
    @include font-size(xlarge);
    @include margin(xlarge, top);
}

h3 {
    @include font-size(large);
    @include margin(xlarge, top);
}

h4,
h5,
h6 {
    @include font-size(regular);
    @include margin(xlarge, top);
}

dt {
    @include font-size(xlarge);
}

p {
    margin-bottom: 0.1rem;
}

a {
    color: color(orange);

    &:hover {
        text-decoration: none;
    }
}

sup {
    font-size: 70%;
    vertical-align: super;
}

sub {
    font-size: 70%;
    vertical-align: sub;
}

.text__text,
.html__text {
    a {
        padding-bottom: 2px;
        text-decoration: none;
        border-bottom: $border--xsmall solid color(orange);

        &:hover {
            border-bottom: none;
        }
    }
}

.introduction {
    @include margin(large, top);

    &--small {
        @include font-size(regular);
    }

    &--big {
        @include font-size(large);
    }
}

.orange {
    color: color(orange);
}
.notification {
    @include font-size(xsmall);
    @include padding(xxsmall);

    &--warning {
        color: color(black);
        background-color: color(white);
        border: $border--xsmall solid color(orange);
    }

    &--danger {
        color: color(white);
        background-color: color(orange);
        border: $border--xsmall solid color(orange);
    }
}
.large {
    @include font-size(large);
}
.small {
    @include font-size(small);
}
.xsmall {
    @include font-size(xsmall);
    p {
        @include margin(xxxsmall, top);
    }
}
.center {
    text-align: center;
}
