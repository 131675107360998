@mixin font-family($font: primary, $weight: normal, $style: normal) {
    $ff-map: map-get($fonts, $font);
    $ff: map-get($ff-map, 'base');
    $fallback: map-get($ff-map, 'fallback');
    font-family: $ff, $fallback;
    font-weight: $weight;
    font-style: $style;
}

@mixin font-size($size: regular, $skip-line-height: false) {
    $fs-breakpoints: $breakpoints;
    $fs-map: map-get($font-size, $size);

    @each $fs-breakpoint, $fs-font-size in $fs-map {
        @if $fs-breakpoint == xs {
            @include make-font-size($fs-font-size, $skip-line-height);
        } @else {
            @if map-has-key($fs-breakpoints, $fs-breakpoint) {
                $fs-breakpoint: map-get($fs-breakpoints, $fs-breakpoint);
            }
            @media screen and (min-width: $fs-breakpoint) {
                @include make-font-size($fs-font-size, $skip-line-height);
            }
        }
    }
}

@mixin make-font-size($fs-font-size, $skip-line-height: false) {
    @if (type-of($fs-font-size) == 'list') {
        font-size: nth($fs-font-size, 1);

        @if ($skip-line-height != true and length($fs-font-size) > 1) {
            line-height: nth($fs-font-size, 2);
        }
    } @else {
        font-size: $fs-font-size;

        @if ($skip-line-height != true) {
            line-height: nth($fs-font-size, 2);
        }
    }
}

@mixin margin($size: regular, $direction: all) {
    @include spacing(margin, $direction, $size);
}

@mixin padding($size: regular, $direction: all) {
    @include spacing(padding, $direction, $size);
}

@mixin spacing($attribute: margin, $direction: all, $size: regular) {
    $fs-breakpoints: $breakpoints;
    $fs-map: map-get($margin, $size);
    $fs-attribute: $attribute;

    @if $direction != all {
        $fs-attribute: #{$attribute}-#{$direction};
    }

    @each $fs-breakpoint, $fs-margin in $fs-map {
        @if $fs-breakpoint == xs {
            #{$fs-attribute}: $fs-margin;
        } @else {
            @if map-has-key($fs-breakpoints, $fs-breakpoint) {
                $fs-breakpoint: map-get($fs-breakpoints, $fs-breakpoint);
            }
            @media screen and (min-width: $fs-breakpoint) {
                #{$fs-attribute}: $fs-margin;
            }
        }
    }
}
