.imagelist {
    list-style: none;
    &__item {
        padding: $spacing--small 0;
        display: table;
        width: 100%;
    }
    &__illustration {
        display: table-cell;
        width: 25%;
    }

    &__image {
        padding-left: $spacing;
    }

    &__content {
        width: 75%;
        display: table-cell;
        vertical-align: middle;
        padding-left: $spacing;
    }

    &__title {
        margin-top: 0;
    }

    &__link {
        color: color(green);
        padding: $spacing--small $spacing--xxxlarge $spacing--small 0;

        &--arrow {
            background-image: url('../svg/arrow-green-circle-right.svg');
            background-size: $spacing--large;
            background-position: right $spacing--small center;
            background-repeat: no-repeat;
        }
    }

    &__description {
        margin-top: 0;
        color: color(black);
    }

    a {
        text-decoration: none;
    }
}
