.autocomplete {
  ul {
    list-style: none;
    border: 1px solid color(green, base);
    margin-bottom: 0;
    margin-top: -2px;

    li {
      cursor: pointer;
      
      button {
        cursor: pointer;
        background-color: inherit;
        width: 100%;
        height: 100%;
        border: none;
        text-align: left;
        padding: 0.6rem 0.8rem;

        &:hover {
          background-color: color(green, base);
          color: white;
        }
      }
    }
  }
}
